import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SearchPage from './pages/SearchPage';
import Courses from './pages/Courses';
function App() {
  return (
<BrowserRouter>
<Routes>
  <Route  path='/'  element={<SearchPage/>} exact/>
  <Route  path='/courses'  element={<Courses/>} exact/>
</Routes>

</BrowserRouter>
  );
}

export default App;
