import React, { useState } from 'react';
import '../App.css'

export default function Autocomplete({ suggestions ,onInputValueChange}) {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState('');
  
    function handleInputChange(event) {
      const value = event.target.value;
      let filtered = [];
      if (value.length > 0) {
        const regex = new RegExp(`^${value}`, 'i');
        filtered = suggestions.filter(suggestion => regex.test(suggestion));
      }
      setFilteredSuggestions(filtered);
      onInputValueChange(value)
      setInputValue(value);
    }
  
    function handleClickSuggestion(value) {
        onInputValueChange(value)
      
        setInputValue(value);

      setFilteredSuggestions([]);
    }
  
    return (
      <>
     
      <div className="autocomplete">
       
        <input
          id="myInput"
          type="text"
          name="myCountry"
          placeholder="Name of Course / Degree"
          value={inputValue}
          onChange={handleInputChange}
        />
        {/* {filteredSuggestions.length > 0 && (
          <div className="autocomplete-items">
            {filteredSuggestions.map((suggestion, index) => (
              <div key={index} onClick={() => handleClickSuggestion(suggestion)}>
                <strong>{suggestion.substr(0, inputValue.length)}</strong>
                {suggestion.substr(inputValue.length)}
                <input type="hidden" value={suggestion} />
              </div>
            ))}
          </div>
        )} */}
      </div>
      </>
    );
  }