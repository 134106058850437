import React, { useState, useEffect, useRef } from 'react';
import '../App.css'
import { IconChevronDown } from '@tabler/icons-react';

const CustomSelect = ({ options, onCountryChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOptionClick = (option) => {
    if(!option.value)
    {
        return;
    }
    setSelectedOption(option.label);
    onCountryChange(option.value)
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="custom-select " ref={selectRef}>
      <div className={`select-selected flex justify-between items-center `} onClick={handleToggle}>
        <h3 className={`capitalize ${!selectedOption?'text-[#808080] ':''}`}>

        {selectedOption ? selectedOption : 'Select Country'}
        </h3>

        <IconChevronDown size={20} />
      </div>
      {isOpen && (
        <div className="select-items">
          {options.map((option, index) => (
            <div
              key={index}
              className={`${option === selectedOption ? "same-as-selected" : ""} ${option=='Select Country'?'text-[#808080]':''}`}
              onClick={() => handleOptionClick(option)}
              
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
