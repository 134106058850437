import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IconLoader2,IconZoomExclamation, IconCaretRightFilled, IconCaretLeftFilled } from '@tabler/icons-react';
import FilterPanel from "../components/FilterPanel";
import FilterPanelMobile from "../components/FilterPanelMobile";
import { IconFilterEdit } from '@tabler/icons-react';


const responseData = [
  {
    university: "Monash University",
    course_name:
      "Agricultural Science and Technology-Bachelor of Agricultural Science and Bachelor of Arts",
    type: "Undergraduate",
    country: "Australia",
    img_path:
      "https://upload.wikimedia.org/wikipedia/commons/7/7c/Monash_University_logo.svg",
  },
  {
    university: "Monash",
    course_name: "Big Data",
    type: "Undergraduate",
    country: "Australia",
    img_path:
      "https://upload.wikimedia.org/wikipedia/commons/7/7c/Monash_University_logo.svg",
  },
  {
    university: "Monash",
    course_name: "Big Data",
    type: "Undergraduate",
    country: "Australia",
    img_path:
      "https://upload.wikimedia.org/wikipedia/commons/7/7c/Monash_University_logo.svg",
  },
  {
    university: "Monash",
    course_name: "Big Data",
    type: "Undergraduate",
    country: "Australia",
    img_path:
      "https://upload.wikimedia.org/wikipedia/commons/7/7c/Monash_University_logo.svg",
  },
];

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPageNumbers = () => {
    const pagesToShow = 4;
    const pages = [];
    let startPage = Math.max(1, currentPage - pagesToShow);
    let endPage = Math.min(totalPages, currentPage + pagesToShow);

    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) {
        pages.push("...");
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push("...");
      }
      pages.push(totalPages);
    }

    return pages;
  };

  return (
    <div className="mt-5 flex justify-center">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="mr-2 px-3 py-1 bg-[#004aad] text-white rounded-md disabled:opacity-50"
      >
      <IconCaretLeftFilled/>
      </button>
      {getPageNumbers().map((page, index) => (
        <button
          key={index}
          onClick={() => onPageChange(page)}
          disabled={page=='...'?true:false}
          className={`px-3 py-2 mx-1 ${
            currentPage === page ? "bg-[#004aad] text-white" : "bg-gray-200"
          } rounded-md`}
        >
          {page}
        </button>
      ))}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-3 py-1 ml-2 bg-[#004aad] text-white rounded-md disabled:opacity-50"
      >
     <IconCaretRightFilled/>
      </button>
    </div>
  );
};
export default function Courses() {
  const location = useLocation();
  const data = JSON.parse(localStorage.getItem('data'));
  // console.log(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows,setTotalRows]=useState(0)
  
  const [loading,setIsLoading]=useState(false)
  const [responseData,setResponseData]=useState(null)
  const [universities,setUniversities]=useState([])
  const [openFilterPanel,setOpenFilterPanel]=useState(false)
  

  const [inputValue, setInputValue] = useState(data.search);
  const [types, setTypes] = useState(data.types);
  const [country, setCountry] = useState(JSON.parse(data.country));
  const [selectedUniversity,setSelectedUniversity]=useState(data.university? JSON.parse(data.university):'')



  const onCountryChange = (e) => {
    console.log(e);
    setSelectedUniversity('')
    setCountry(e);
    getUniversities(e.value)
  };

  const handleType = (e) => {
    if (!types.includes(e.target.value)) {
      setTypes([...types, e.target.value]);
    } else {
      setTypes(types.filter((type) => type !== e.target.value));
    }
  };


  const searcResult = async (pageNumber) => {
    setIsLoading(true)
   

      axios
      .post(`https://friends-consultant.vercel.app/course/search`, {
        // .post(`http://localhost:3000/course/search`, {
          type:[],
        course: data.search,
        country: country.value,
        university:selectedUniversity.value,
        pageNumber: pageNumber,
      })
      .then((res) => {
        
        setCurrentPage(pageNumber)
        setResponseData(res.data.results)
        setTotalRows(res.data.totalCount)
        
        setIsLoading(false)
        
        getUniversities(country.value)
        console.log(res);
      })
      .catch((err) => {
        setIsLoading(false)
        
        console.log(err);
      });
   
    };
  useEffect(() => {
    searcResult(1);
  }, []);

  const onFilterSelection=(course_name,type,country,university, isMobile=false)=>
  {
    console.log(course_name,country,type);

    setIsLoading(true)


    axios
      .post(`https://friends-consultant.vercel.app/course/search`, {
      type:type,
      course: course_name,
      country: country.value,
      university:university.value,
      pageNumber: 1,
    })
    .then((res) => {
      
      setCurrentPage(1)
      setResponseData(res.data.results)
      setTotalRows(res.data.totalCount)
      setIsLoading(false)
      
      if(isMobile)
        {
         setOpenFilterPanel(false) 
        }
        localStorage.setItem('data',JSON.stringify({
          search:course_name,
          country:JSON.stringify(country),
          university:JSON.stringify(university),
          types:types
  
      }))
    
      console.log(res);
    })
    .catch((err) => {
      setIsLoading(false)
      
      console.log(err);
    });

  }
  
  const getUniversities=(country)=>
    {
         
      axios.get(`https://friends-consultant.vercel.app/course/getUniversities?country=${country}`).then((res)=>
        {
          const data=[]
          res.data.results.map((uni)=>
          {
            data.push(uni)
          })
          console.log(res.data.results);
          console.log((res.data.results.length));
          setUniversities(res.data.results)

        }).catch((err)=>
        {
          console.log('error',err);
        })
    }
  const totalPages = Math.ceil(totalRows / 40); // Assuming 12 items per page

  const handlePageChange = (page) => {
    searcResult(page);

  };

  const setOpenFilterPanelVal=(val)=>
  {
    setOpenFilterPanel(val)
  }
  
  const handleInputChange=(e)=>
    {
      setInputValue(e.target.value)
    }

    const onUniversitySelectionChange=(e)=>
      {
        console.log(e);
        setSelectedUniversity(e)
      }
  return (
    <div className="pb-2">
      <div className="pl-5 mt-1 border-b-2 py-1">
        <img src="/aiec.png" alt="AIEC" className="h-24 " />
      
      </div>

      <div className="mt-5 md:block px-10 flex justify-between items-center">
        <h1 className="font-semibold  text-[#004aad] text-2xl  ">
          Total Results (
          {totalRows}
          )
        </h1>

        <IconFilterEdit onClick={()=>setOpenFilterPanelVal(true)} className="md:hidden block"/>
      </div>

<div className="grid grid-cols-12  md:mx-2  rounded-md py-2 md:px-2 max-h-screen overflow-y-auto">

<div className="col-span-0 md:col-span-3 ">

      <FilterPanel country={country } initial_search={data.search} onFilteration={onFilterSelection} universities={universities} getUniversities={getUniversities} handleType={handleType} types={types} onCountryChange={onCountryChange} inputValue={inputValue} handleInputChange={handleInputChange} selectedUniversity={selectedUniversity} onUniversitySelectionChange={onUniversitySelectionChange}/>
      <FilterPanelMobile isSidebarOpen={openFilterPanel} setIsSidebarOpen={setOpenFilterPanelVal} country={country } initial_search={data.search} onFilteration={onFilterSelection} universities={universities} getUniversities={getUniversities} onCountryChange={onCountryChange} handleType={handleType} types={types} inputValue={inputValue} handleInputChange={handleInputChange} selectedUniversity={selectedUniversity} onUniversitySelectionChange={onUniversitySelectionChange}/>
</div>

<div className="col-span-12 md:col-span-9 max-h-[calc(100vh-210px)] min-h-[calc(100vh-212px)] overflow-y-auto">
  {
loading?
<div className="flex justify-center mt-10">

<div className="p-2 bg-[#eb6a18] animate-spin rounded-full">
  <IconLoader2 className="h-6 text-white"/>
  </div>
  </div>
: !loading && !responseData || responseData.length==0?
<div className="flex justify-center mt-10">

<div className="p-2   rounded-full ">
  <div className="flex justify-center">

  <IconZoomExclamation size={40} className=" text-[#fa4251] "/>
  </div>
  <div className="text-center text-lg">

  <h3>No Course found for <br></br> <strong>"{data.search}"</strong> </h3>
  </div>
  </div>
  </div>
:

      <div>
        
      <div className=" grid grid-cols-1  lg:grid-cols-2 xl:grid-cols-3 gap-5 px-10">
        {responseData &&
          responseData.length > 0 &&
          responseData.map((item, index) => {
            return (
              <div
                key={index}
                className="bg-[#F5F4F4] rounded-md shadow-md py-2 flex flex-col"
              >
                <div className="min-h-96 flex-grow ">
                  <div className="h-[200px] flex justify-center items-center border-b-2 border-[#eb6a18] border-dotted">

                  <img
            
                    src={item.img_path}
                    alt={"uniImage" + index}
                    className=" px-2 bg-contain  md:mix-blend-multiply"
                  />
                  </div>
                  <div className="grid grid-cols-3 min-h-[220px]  text-lg px-4 mt-2 gap-y-2">
                    <h2>University</h2>
                    <h2 className="col-span-2 font-medium">
                      {item.university}
                    </h2>

                    <h2>Course</h2>
                    <h2 className="col-span-2 font-medium">
                      {item.course_name}
                    </h2>

                    <h2>Type</h2>
                    <h2 className="col-span-2 font-medium">{item.type}</h2>

                    <h2>Country</h2>
                    <h2 className="col-span-2 font-medium">{item.country}</h2>
                  </div>
                </div>
                <div className="h-10  bg-[#eb6a18] mt-2 cursor-pointer mx-2 rounded-md flex justify-center items-center mt-1">

                <a href={`https://apply.aiec.pk/?id=${item.index}`} className="">
                  <h1 className="text-xl text-white">Apply Now</h1>
                </a>
                </div>
              </div>
            );
          })}
          
      </div>

      {totalPages > 1 && (
     

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
    
      )}
    </div>
}

</div>

</div>
    </div>
  );
}
