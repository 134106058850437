import React, { useState } from 'react';
import '../App.css'
import Autocomplete from '../components/AutoComplete';
import { useNavigate } from 'react-router-dom';
import CustomSelect from '../components/CustomSelect';


function SearchPage() {

    const [country,setCountry]=useState('')
    const [search,setSearch]=useState('')
    const navigate = useNavigate();
    const countriesList = [
      { label: 'Select Country', value: '' },
      { label: 'USA', value: 'usa' },
      { label: 'UK', value: 'uk' },
      { label: 'Australia', value: 'australia' },
      { label: 'China', value: 'china' }
    ];
  
  const countries = [
    'Big Data',
    'Machine Learning',
    'MBBS',
    'Graphic Designing',
    'MBA',
    'Engineering',
    'MBA',
    'Biotechnology'
  ];

  const onInputValueChange=(val)=>
  {
    setSearch(val)
  }

  const onSearchClick=()=>
  {
    
    console.log(country)
    console.log(search)
    if(search && country)
    {

      localStorage.clear()
      localStorage.setItem('data',JSON.stringify({
        search:search,
        country:JSON.stringify({label:country==="uk"?"UK":country==="usa"?"USA":country==="china"?"China":"Australia",value:country}),
        university:'',
        types:''

    }))
        navigate('/courses')
    }
  }
  const onCountryChange=(country)=>
  {
    setCountry(country)
  }
  return (
    <div className="s013">
            <div className='absolute top-5 left-3 md:left-5'>
            <img src='/aiec.png' alt='AIEC' className='h-24 md:h-32 '/>
        </div>
      <form>
        <fieldset>
          <legend>Find Courses & Universities</legend>
        </fieldset>
        <div className="inner-form">
          <div className="left">
            <div className="input-wrap first">
              <div className="input-field first">
                <label>Courses</label>
                <Autocomplete suggestions={countries} onInputValueChange={onInputValueChange} />
              </div>
            </div>
            <div className="input-wrap second">
              <div className="input-field second">
                <label>Country</label>
               
            <CustomSelect options={countriesList}
            
             onCountryChange={onCountryChange}
             />
              </div>
            </div>

          </div>
          <button className="btn-search" type="button" onClick={onSearchClick}>
            SEARCH
          </button>
        </div>
      </form>
    </div>
  );
}

export default SearchPage;
